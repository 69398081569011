@import '../../assets/styles/variables.scss';

.homeSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 10rem;
    background-color: $background1-color;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 2rem;
    }
}

.content {
    flex: 1;

    h1 {
        font-size: 3.5rem;
        color: $primary-color;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }
}

.ctaButton {
    padding: 1rem 2rem;
    background-color: $primary-color;
    color: $background2-color;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: $buttonHover-color
    }
}

.imageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    img {
        max-width: 90%;
        border-radius: 10px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
}
