.scrollToTopButton {
    position: fixed;
    bottom: 2%;
    right: 2%;
    background-color: rgba(0, 0, 0, 0.5); // semi-transparent black
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    opacity: 0; // Initial state is invisible
    transition: opacity 0.3s ease-in-out; // Smooth transition for the opacity property
  
    &:hover {
      background-color: rgba(0, 0, 0, 0.8); // slightly less transparent on hover
    }

  }
  
  .show {
    opacity: 1; // Visible state
  }
  