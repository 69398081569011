@import '../../assets/styles/variables.scss';

.aboutSection {
  padding: 5% 10%;
  background-color: $background1-color;
  font-family: 'Arial', sans-serif;
  color: $text-color;

  h2, h3 {
    color: $primary-color;
    margin-bottom: 20px;
    font-weight: 600;
  }

  p {
    margin-bottom: 30px;
    line-height: 1.6;
    font-size: 1.1rem;
  }

  .intro img {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0px 10px 25px rgba(0,0,0,0.1);
    margin-top: 20px;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      margin-bottom: 15px;
      font-weight: 500;

      .service-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: $primary-color;
        mask: url('../../assets/images/svg/Gear.svg') no-repeat 50% 50%;
        mask-size: contain;
        margin-right: 10px;
      }
    }
  }

  .cta {
    text-align: center;
    margin-top: 50px;

    button {
      background-color: $primary-color;
      color: white;
      padding: 15px 30px;
      border: none;
      border-radius: 5px;
      font-size: 1.2rem;
      cursor: pointer;
      transition: background-color 0.3s, transform 0.2s;

      &:hover {
        background-color: $buttonHover-color;
        transform: translateY(-3px);
      }

      &:active {
        transform: translateY(0);
      }
    }
  }

  .cardContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; // Allow wrapping for smaller screens
    margin-bottom: 50px; // Separate it from the content below
    margin-top: 50px;
  }
  
  .card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1; // Take equal width
    margin: 0 15px; // A little separation between cards
    max-width: calc(50% - 30px); // Ensuring there's spacing on large screens

    transition: transform 0.3s ease; // Smooth transition for the transform property

  &:hover {
    transform: scale(1.05); // Enlarge the card by 5%

    // Optional: If you want the content inside the card to enlarge as well
    h3, p {
      transform: scale(1.05);
    }
  }

  h3 {
    border-bottom: 2px solid $primary-color;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }

  p {
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .cardContainer {
    flex-direction: column; // Stack the cards vertically on smaller screens
  }

  .card {
    margin: 15px 0; // Adjust margin for stacked cards
    max-width: 100%;

    // Remove the hover transformation effect
    &:hover {
      transform: none;

      h3, p {
        transform: none;
      }
    }
  }
}
}