@import '../../assets/styles/variables.scss';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background-color: $background1-color;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  height: 10vh;
  position: relative;
  border-top: 1px solid $primary-color;
  border-bottom: 1px solid $primary-color;
  z-index: 1000;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30px;
  height: 20px;
  cursor: pointer;
  transition: transform 0.3s;
}

.hamburgerMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
  transition: transform 0.3s;
}

.hamburgerLine {
  width: 100%;
  height: 2px;
  background-color: black;
  transition: background-color 0.3s;
}

.open .hamburgerLine {
  background-color: $primary-color;
}

.logoContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 100%;
  width: 100%;
  //transition: all 0.3s;
}

.contactSales {
  display: flex;
  align-items: center;
}

.contactButton {
  background-color: $primary-color;
  color: $background2-color;
  border-radius: 25px;
  border: none;
  padding: 8px 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.2rem;
}

.contactButton:hover {
  background-color: rgba(13, 82, 199, 0.6);
}


.mobileMenu {
  position: absolute; // Position the mobile menu right under the hamburger
  top: 100%;
  left: 0;
  background-color: white;
  z-index: 3; // Ensure it appears above other elements
  display: flex;
  flex-direction: column;
  border: 1px solid $primary-color;

  a {
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: black;

    &:hover {
      background-color: $primary-color;
      color: white;
    }
  }
}

@media screen and (max-width: 768px) {
  .logo {
    height: 60%;  /* increased size */
    width: 60%;   /* increased size */
  }
  
  .contactButton {
    font-size: 0.9rem;     /* decreased font size */
    padding: 6px 15px;     /* decreased padding */
  }
}
