@import '../../assets/styles/variables.scss';

.main {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.stickyContainer {
  position: sticky;
  top: 0;
  z-index: 1000; /* Adjust the z-index as needed */
}


.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: $primary-color;
  color: $text-color;
  
  @media (min-width: 768px) {
    width: 80%;
    //max-width: 1200px;
    margin: 0 auto;
  }
}




