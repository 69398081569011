// ContactForm.module.scss

@import '../../assets/styles/variables.scss';

.contactFormContainer {
  max-width: 500px;
  margin: 2rem auto;
  padding: 1rem;
  border: 1px solid $primary-color;
  border-radius: 10px;
  background-color: $background1-color;
}

.formTitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.formInput, .formSelect, .formTextarea {
  width: 80%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: 1px solid $primary-color;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: darken($primary-color, 15%);
  }
}

.formSelect {
  appearance: none;
  background-color: white;
  cursor: pointer;
}

.otherBusinessType {
  display: none;
  margin-left: 0.5rem;
}

.formButton {
  display: block;
  margin: 1rem auto;
  padding: 0.8rem 1.5rem;
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}
