@import '../../assets/styles/variables.scss';

.statisticBreak {
    background-color: #f2f2f2; // A light grey, but consider using a color from your palette
    padding: 20px 0;
    text-align: center;
    margin: 40px 0; // Giving a margin to separate it from the two sections
    border-top: 2px solid $primary-color; // A border at the top for added distinction
    border-bottom: 2px solid $primary-color; // A border at the bottom for added distinction
  }
  
  .statisticText {
    font-size: 1.4rem; 
    color: $text-color; 
    max-width: 800px; 
    margin: 0 auto; 
    line-height: 1.6;
  }
  
  .source {
    display: block; 
    margin-top: 10px;
    font-style: italic;
  }
  