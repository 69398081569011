@import '../../assets/styles/variables.scss';

.servicesSection {
  padding: 5% 10%;
  background-color: $background1-color;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tagline {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 2vh;
  color: $primary-color;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; 
  margin: 30px 0;
  width: 100%;
}

.serviceCard {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1;
  margin: 10px;
  max-width: calc(50% - 30px);

  transition: transform 0.3s ease; // Smooth transition for the transform property
  
  h3 {
    border-bottom: 2px solid $primary-color;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  &:hover {
    transform: scale(1.05); // Enlarge the card by 5%

    // Optional: If you want the content inside the card to enlarge as well
    h3, p {
      transform: scale(1.05);
    }
  }
}

@media screen and (max-width: 768px) {
  .tagline {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .cardsContainer {
    flex-direction: column;
  }
  
  .serviceCard {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

.learnMore {
  display: inline-block;
  background-color: $primary-color;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px;
  transition: background-color 0.3s ease-in-out;
}

.learnMore:hover {
  background-color: $buttonHover-color;
}
