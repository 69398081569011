@import '../../assets/styles/variables.scss';

.contactSection {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2; // Using a similar background as StatsSection for cohesion
    height: 35vh;
    border-top: 2px solid $primary-color;
    border-bottom: 2px solid $primary-color;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
}

.content {
    display: flex;
    align-items: center;
    justify-content: center; // Centered content for better balance
    padding: 1rem;
    width: 100%;
}

.taglineContainer {
    background-color: white;
    color: $text-color;
    padding: 2rem; // Increased padding for better spacing
    width: 80%;
    max-width: 500px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // A shadow to lift the card visually
    margin-left: 10%; // Centered the container
    margin-right: 10%; 

    @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        padding: 1rem;
        margin-left: 10%;
        margin-right: 10%;
        width: 80%;
        height: auto;
    }
}

.catchphrase {
    font-size: 1.4rem; // Adjusted to match statisticText in StatsSection
    margin-bottom: 20px; // Space between text and button
    line-height: 1.6;
}

.callButton {
    display: inline-block;
    background-color: $primary-color;
    color: $background2-color;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
}

.callButton:hover {
  background-color: $buttonHover-color
}
